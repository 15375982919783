module.exports = {
  place: "Объявление о конкурсе",
  commision: "Председатель комиссии",
  organization: "Организации",
  apply: "Участвующие в конкурсе",
  plan: "Учебный план",
  staff: "Сотрудники",
  branch: "Объекты",
  training: "Курсы повышения",
  "months": {
    "january": "Январь",
    "february": "Февраль",
    "march": "Март",
    "april": "Апрель",
    "may": "Май",
    "june": "Июнь",
    "july": "Июль",
    "august": "Август",
    "september": "Сентябрь",
    "october": "Октябрь",
    "november": "Ноябрь",
    "december": "Декабрь"
  }
};

