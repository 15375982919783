export default {
  path: "/superadmin",
  name: "superadmin",
  component: () => import("@/views/LayoutView.vue"),
  children: [
    {
      path: "",
      redirect: "accounts",
    },

    {
      path: ":dir(accounts)/view/:id",
      component: () => import("@/views/superadmin/roles/RoleList.vue"),
      props: route => ({
        getDataUrl: `/accounts/${route.params.id}`,
      }),
    },

    {
      path: ":dir(tickets)/(list)*",
      component: () => import("@/views/superadmin/ticket/TicketList.vue"),
    },

    {
      path: ":dir(tickets)/view/:id",
      component: () => import("@/views/superadmin/ticket/TicketView.vue"),
    },

    {
      path: ":dir(tickets)/statistic",
      component: () => import("@/views/superadmin/ticket/TicketStatistic.vue"),
    },

    {
      path: ":dir(organizations)/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
      // component: () => import("@/views/superadmin/organizations/OrganizationsList.vue"),
    },

    {
      path: ":dir(organizations)/view/:id",
      component: () => import("@/views/superadmin/branches/BranchList.vue"),
      props: route => ({
        getDataUrl: `/organizations/${route.params.id}`,
      }),
    },

    {
      path: ":dir(gos-organizations)/(list)*",
      component: () => import("@/views/superadmin/organizations/OrganizationsList.vue"),
    },

    {
      path: ":dir(head-acts)/(list)*",
      component: () => import("@/views/superadmin/organizations/OrganizationsList.vue"),
    },

    {
      path: ":dir/(list)*",
      component: () => import("@/components/default/list/DefaultList.vue"),
    },

    {
      path: ":dir/view/:id",
      component: () => import("@/components/default/view/DefaultView.vue"),
    }

  ],
};
